html {
  font-size: 16px !important;
}

*:focus:focus-visible {
  outline-style: unset !important; /* Removes black outline set by Eui styles */
}

.euiSelectableListItem,
.euiRangeTooltip__value,
.euiToolTip,
.euiModal .euiButton,
.euiModal .euiButtonEmpty,
.euiStat .euiText {
  font-size: 0.875rem !important;
}

.euiQuickSelectPopover__section {
  overflow-y: hidden;
}

.euiDatePickerRange {
  box-shadow: none;
  padding: 0;
}

.euiDatePickerRange .euiDatePicker.euiFieldText {
  padding: 6px;
}

.euiPopoverFooter {
  margin: 0 !important;
}

.euiDatePickerRange, .euiFieldSearch {
  background: var(--mantine-color-white);
}

.euiTableRow {
  &[data-flagged="true"] {
    background: var(--mantine-color-orange-1) !important;

    @mixin smaller-than $mantine-breakpoint-sm {
      border: 1px solid var(--mantine-color-orange-2);
    }

    @mixin larger-than $mantine-breakpoint-sm {
      .euiTableRowCell {
        border-top: 1px solid var(--mantine-color-orange-2);
        border-bottom: 1px solid var(--mantine-color-orange-2);
      }
    }
  }

  &[data-blocked="true"] {
    background: var(--mantine-color-red-1) !important;

    @mixin smaller-than $mantine-breakpoint-sm {
      border: 1px solid var(--mantine-color-red-2);
    }

    @mixin larger-than $mantine-breakpoint-sm {
      .euiTableRowCell {
        border-top: 1px solid var(--mantine-color-red-2);
        border-bottom: 1px solid var(--mantine-color-red-2);
      }
    }
  }

  &[data-priority="true"] {
    background: var(--mantine-color-blue-1) !important;

    @mixin smaller-than $mantine-breakpoint-sm {
      border: 1px solid var(--mantine-color-blue-2);
    }

    @mixin larger-than $mantine-breakpoint-sm {
      .euiTableRowCell {
        border-top: 1px solid var(--mantine-color-blue-2);
        border-bottom: 1px solid var(--mantine-color-blue-2);
      }
    }
  }
}
